import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare, faGithub } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faBan,
  faBell,
  faBold,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faCog,
  faCopy,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faEllipsisH,
  faGem,
  faGlobe,
  faHashtag,
  faHeadset,
  faInfoCircle,
  faItalic,
  faList,
  faListOl,
  faListUl,
  faMoon,
  faPlus,
  faRedo,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSpinner,
  faSquare,
  faStar,
  faStickyNote,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnderline,
  faUndo,
  faUser,
  faExclamationTriangle,
  faPalette,
  faCubes,
  faFilter,
  faDatabase,
  faCode,
  faBook,
  faBookOpen,
  faLink,
  faBorderStyle,
  faFileAlt,
  faFileCode,
  faEye,
  faPencilAlt,
  faExclamation,
  faDivide,
  faStore,
} from '@fortawesome/free-solid-svg-icons';

// Adds icons used in Design System.
// Other applications should import additional icons if needed.
library.add(
  faArrowLeft,
  faArrowRight,
  faBan,
  faBan,
  faBell,
  faBold,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faCog,
  faCopy,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faEllipsisH,
  faFacebookSquare,
  faGem,
  faGithub,
  faGlobe,
  faHashtag,
  faHeadset,
  faInfoCircle,
  faItalic,
  faList,
  faListOl,
  faListUl,
  faMoon,
  faPlus,
  faRedo,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSpinner,
  faSquare,
  faStar,
  faStickyNote,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnderline,
  faUndo,
  faUser,
  faExclamationTriangle,
  faPalette,
  faCubes,
  faFilter,
  faDatabase,
  faCode,
  faBook,
  faBookOpen,
  faLink,
  faBorderStyle,
  faFileAlt,
  faFileCode,
  faEye,
  faPencilAlt,
  faExclamation,
  faDivide,
  faStore
);

config.keepOriginalSource = false;

// https://docs.fontawesome.com/apis/javascript/methods#domwatchparams
dom.watch();
